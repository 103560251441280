import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import HeaderBar from '../../../components/courses/HeaderBar';
import CashIcon from '../../../../assets/icons/dollar-bills.svg';
import CardIcon from '../../../../assets/icons/credit-card.svg';
import CheckIcon from '../../../../assets/icons/tick.svg';
import Select from '../../../components/fields/Select';
import isEmpty from '../../../utils/isEmpty';
import stripePk from '../../../utils/stripePk';
import { navigate } from 'gatsby';

const plans = [
  {
    name: 'Taller de JavaScript y de ReactJS',
    sku: 'sku_G0ziZWkFWiqGzw',
    price: 450
  },
  {
    name: 'Taller de HTML+CSS y de ReactJS',
    sku: 'sku_G0ziWHH4u1CSzT',
    price: 400
  },
  {
    name: 'Taller de HTML+CSS y de JavaScript',
    sku: 'sku_G0zhlUiVWMxe43',
    price: 350
  },
  { name: 'Taller de ReactJS', sku: 'sku_G0zg7LpXA5tcIv', price: 250 },
  { name: 'Taller de HTML+CSS', sku: 'sku_G0zgeLTaKq39Ov', price: 150 },
  { name: 'Taller de JavaScript', sku: 'sku_G0zgEw8hXjm7Tb', price: 200 }
];

const SignUp = () => {
  const workshopValues = ['HTML+CSS', 'JavaScript', 'ReactJS'];

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [university, setUniversity] = useState('');
  const [number, setNumber] = useState('');
  const [method, setMethod] = useState('card');
  const [reason, setReason] = useState('');
  const [medium, setMedium] = useState('Facebook');
  const [workshops, setWorkshops] = useState(workshopValues);
  const [errorMessage, setErrorMessage] = useState(null);

  const addWorkshop = workshop => {
    let newWorkshops = [...workshops];
    newWorkshops.push(workshop);
    setWorkshops(newWorkshops);
  };

  const removeWorkshop = workshop => {
    let newWorkshops = [...workshops];
    newWorkshops.splice(newWorkshops.indexOf(workshop), 1);
    setWorkshops(newWorkshops);
  };

  const getSelectedPlan = () => {
    let selectedPlan;
    switch (workshops.length) {
      case 1:
        selectedPlan = plans.find(plan => plan.name.includes(workshops[0]) && !plan.name.includes(' y '));
        break;
      case 2:
        selectedPlan = plans.find(plan => plan.name.includes(workshops[0]) && plan.name.includes(workshops[1]));
        break;
      case 3:
        selectedPlan = {
          name: 'Todos los talleres',
          sku: 'sku_G0zcYd0gRkFqoH',
          price: '500 (ahorras $100)'
        };
        break;
      default:
        selectedPlan = { price: 0 };
        break;
    }
    return selectedPlan;
  };

  const onContinue = async () => {
    // TODO: Improve validation
    if (isEmpty(name) || isEmpty(email) || isEmpty(number) || isEmpty(university) || workshops.length === 0) {
      setErrorMessage('Te faltó información importante. Verifica e intenta de nuevo.');
      return;
    }

    let selectedPlan = getSelectedPlan();

    try {
      const res = await fetch('https://hooks.zapier.com/hooks/catch/4630101/ou14bua/', {
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          university,
          number,
          method,
          reason,
          medium,
          workshops
        }),
        headers: {
          Accept: 'application/json'
        }
      });

      const data = await res.json();
    } catch (error) {
      console.log(error);
    }

    if (method == 'cash') {
      navigate('/courses/frontend/success-cash');
      return;
    }

    const stripe = window.Stripe(stripePk);

    stripe
      .redirectToCheckout({
        items: [{ sku: selectedPlan.sku, quantity: 1 }],
        successUrl: 'https://positive0.com/courses/frontend/success',
        cancelUrl: 'https://positive0.com/courses/frontend',
        customerEmail: email
      })
      .then(async function(result) {
        if (result.error) {
          var displayError = document.getElementById('error-message');
          displayError.textContent = result.error.message;
        }
      });
  };

  return (
    <Layout>
      <Seo
        title='Registro de Frontend Web Developer Workshop'
        keywords={['web', `workshop`, `taller`, `positive zero`]}
      />
      <div className='content'>
        <h1>Frontend Web Developer Workshop</h1>
        <h2>Se han agotado los lugares</h2>
        <p>Gracias por tu interés en el taller. Mántente alerta para futuras ediciones de este evento.</p>
        {/*<section className='global-section'>
           <HeaderBar>Datos generales</HeaderBar>
          <div className='input-container'>
            <input
              value={name}
              onChange={e => setName(e.target.value)}
              name='name'
              type='text'
              className='global-input'
              placeholder='Nombre completo'
            />

            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              name='email'
              type='email'
              className='global-input'
              placeholder='Correo electrónico'
            />

            <input
              value={number}
              onChange={e => setNumber(e.target.value)}
              name='number'
              type='text'
              className='global-input'
              placeholder='Número de teléfono'
            />

            <input
              value={university}
              onChange={e => setUniversity(e.target.value)}
              name={'university'}
              type='text'
              className='global-input'
              placeholder='Universidad'
            />

            <Select
              label='¿Cómo te enteraste de este taller?'
              options={['Facebook', 'Amigos', 'Tabloides']}
              value={medium}
              onChange={value => setMedium(value)}
            />

            <Select
              label='¿Por qué quieres tomar este taller?'
              options={[
                'Curiosidad',
                'Desarrollo profesional',
                'Reforzar conocimiento',
                'Mejorar currículum',
                'Otra'
              ]}
              value={reason}
              onChange={value => setReason(value)}
            />
          </div> 
        </section>

        <section className='global-section'>
          <HeaderBar>Módulos</HeaderBar>
          <p>Elige los módulos que desees tomar.</p>
          <div className='workshops-container'>
            {workshopValues.map(workshopValue => (
              <a
                className={`workshop ${
                  workshops.includes(workshopValue) ? 'accent' : 'gray'
                }`}
                onClick={() =>
                  workshops.includes(workshopValue)
                    ? removeWorkshop(workshopValue)
                    : addWorkshop(workshopValue)
                }
              >
                {workshops.includes(workshopValue) && (
                  <div className='check-container'>
                    <CheckIcon width='1em' height='1em' fill='#FFA500' />
                  </div>
                )}
                <span>{workshopValue}</span>
              </a>
            ))}
          </div>
          {workshops.length != 0 && <p>Precio: ${getSelectedPlan().price}</p>}
        </section>

        <section className='global-section'>
          <HeaderBar>Método de pago</HeaderBar>

          <div className='payment-methods-container'>
            <div className='payment-method-container'>
              <a
                className={`payment-method ${
                  method == 'card' ? 'accent' : 'gray'
                }`}
                onClick={() => setMethod('card')}
              >
                <CardIcon
                  width='40px'
                  height='40px'
                  fill={method == 'card' ? '#FFA500' : 'gray'}
                />
                <span className={'icon-label'}>Tarjeta</span>
              </a>
              <small className='pay-date'>Pago inmediato</small>
            </div>

            <div className='payment-method-container'>
              <a
                className={`payment-method ${
                  method == 'cash' ? 'accent' : 'gray'
                }`}
                onClick={() => setMethod('cash')}
              >
                <CashIcon
                  width='40px'
                  height='40px'
                  fill={method == 'cash' ? '#FFA500' : 'gray'}
                />
                <span className={'icon-label'}>Efectivo</span>
              </a>
              <small className='pay-date'>Pago antes del curso</small>
            </div>
          </div>
        </section>

        <p className='error-message'>{errorMessage}</p>

        <div className='button-container'>
          <button onClick={onContinue}>CONTINUAR</button>
        </div>
        */}
      </div>

      <style jsx>{`
        .content {
          margin-bottom: 200px;
        }

        .input-container {
          max-width: 500px;
        }

        input {
          margin-bottom: 30px;
        }

        .payment-methods-container {
          display: flex;
          flex-wrap: wrap;
        }

        .payment-method-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 10px;
        }

        .pay-date {
          color: gray;
        }

        .payment-method {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 15px;
          border: 3px solid gray;
          border-radius: 8px;
          margin: 15px;
          user-select: none;
        }

        .workshops-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }

        .workshop {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 8px 15px;
          border: 3px solid gray;
          border-radius: 8px;
          margin-top: 15px;
          margin-right: 15px;
          user-select: none;
        }

        .accent {
          border-color: #ffa500;
        }

        .gray {
          border-color: gray;
        }

        .icon-label {
          margin-top: 10px;
        }

        .button-container {
          margin-top: 50px;
        }

        .check-container {
          margin-right: 10px;
        }

        .error-message {
          color: red;
        }
      `}</style>
    </Layout>
  );
};

export default SignUp;
